.tiptap {
  border: 3px solid var(--theme-text-color);
  border-radius: 10px;  
  padding: 8px;

  ol, ul {
    padding-left: 24px;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
}