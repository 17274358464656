@import 'libs/common/styles/functions/functions.scss';

.ngx-quill-view-html .ql-editor {
  padding-right: 0!important;
  padding-left: 0!important;
  padding-top: 0;
}

.ql-editor {
  font: 400 16px / 26px Graphik;
  ol {
    padding-left: rz-space-multiplier(1)!important;
  }
  ul {
    padding-left: rz-space-multiplier(1)!important;
  }

  &.ql-blank::before {
    color: var(--theme-text-color);
  }
}

.app-dark-theme {
  .ql-snow .ql-stroke {
    stroke: var(--theme-text-color)!important;
  }
  .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: var(--theme-text-color)!important;
  }
}
