.app-dark-theme .mat-snack-bar-container.warn, .app-light-theme .mat-snack-bar-container.warn{
  background-color: #ea414f;
}
.mat-mdc-snack-bar-label .mdc-snackbar__label {
  color: var(--theme-text-color)!important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--primary-lighter-color)!important
}

.mat-mdc-snack-bar-label{
  background-color: var(--primary-lighter-color)!important
}