$radio-button-width: 14px;

.app-dark-theme .mdc-radio__outer-circle {
  border-color: var(--theme-disabled-text)!important;
}

.mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--mdc-radio-selected-icon-color, #f44336)!important;
}

.small-radio-button {
  height: $radio-button-width;
  width: $radio-button-width;
  .mat-mdc-radio-container{
    height: $radio-button-width !important;
    width: $radio-button-width !important;
  }
  .mat-mdc-radio-outer-circle {
    height: $radio-button-width !important;
    width: $radio-button-width !important;
  }
  .mdc-radio {
    height: $radio-button-width !important;
    width: $radio-button-width !important;
    padding: 0!important;
  }

  .mat-mdc-radio-touch-target {
    height: $radio-button-width !important;
    width: $radio-button-width !important;
  }

  .mat-mdc-radio-inner-circle{
    height: $radio-button-width !important;
    width: $radio-button-width !important;
  }
  .mdc-radio__background {
    position: absolute;
    top: -2px;
    height: $radio-button-width !important;
    width: $radio-button-width !important;
  }

  .mdc-radio__inner-circle {
    border-width: ($radio-button-width / 2)!important;
  }
  
  .mdc-radio__background::before {
    display: none!important;
  }

  .mat-radio-ripple{
    height: ($radio-button-width * 2) !important; 
    width: ($radio-button-width * 2) !important; 
    left: calc(50% - $radio-button-width) !important; 
    top: calc(50% - $radio-button-width) !important; 
  }
}