.app-dark-theme input, .app-dark-theme textarea {
  caret-color: var(--theme-text-color)!important;
  color: var(--theme-text-color)!important;
  &::placeholder {
    color: var(--theme-disabled-text)!important;
  }
}

input, textarea {
  background-color: transparent;
}
