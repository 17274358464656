mat-sidenav, mat-drawer {
  min-width: 301px;
}

.mat-drawer {
  background-color: var(--theme-card-background-color);
}

.mat-list-item-content {
  color: var(--theme-text-color);
}

.side-nav-text {
  margin-left: 12px;
}

.mat-list-base .mat-list-item.mat-list-item {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  font-size: 20px;
  &:hover,
  &.active-link {
    background-color: #143a30;
    .mat-list-item-content {
      color: var(--theme-accent-text);
    }
  }
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}
