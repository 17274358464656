.app-light-theme .mat-toolbar.mat-primary {
  color: var(--theme-white-text-color)!important;
}

// used specifically within wysiwyg settings
.mat-toolbar.mat-toolbar-narrow {
  height: 40px;
}

.app-dark-theme .mat-toolbar.top-header {
  background-color: var(--theme-toolbar-background-color)!important;
}
