.app-dark-theme .mat-expansion-panel-header.mat-expanded:focus {
  background: var(--primary-lighter-color);
}

.app-dark-theme .mat-expansion-panel-content {
  background: var(--primary-lighter-color);
}

.app-dark-theme .mat-expansion-panel-header.mat-focus-indicator {
  background: var(--primary-lighter-color);
}

.app-dark-theme .mat-expansion-panel-header.mat-expanded:hover {
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
}

.file-tree {
  $mat-header-height: 32px;
  .mat-expansion-panel-header{
    .mat-content {
      display: inline-block; /* for inline elements e.g. span, strong, em etc */
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 1px;
    }
  }

  .mat-expansion-panel-body {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-expansion-panel-content {
    overflow: scroll;
  }

  .mat-expansion-panel-content, .mat-expansion-panel-body, .panel-container {
    flex: 1;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// used for code chat
.full-height {
  .mat-expansion-panel-content {
    height: 100%;
    .mat-expansion-panel-body {
      height: 100%;
    }
  }
}

.GenerateCode .mat-expansion-panel-content {
  max-height: 350px;
  overflow: scroll;
}

.app-dark-theme .file-tree .mat-expansion-panel-body {
  background: #202327;
}
