mdc-icon {
  margin-right: 0 !important;
  vertical-align: middle;
}

// for the header notification bell
.mdc-icon-button {
  .mat-badge-content {
    top: 0!important;
    right: 0!important;
  }
}
