@import 'libs/common/styles/functions/functions.scss';

.app-dark-theme .mat-mdc-card.child-mat-card {
  background: var(--primary-lighter-color)!important;
}

.mat-card-no-padding {
  .mat-mdc-card-header {
    padding: 0 !important;
  }
  .mat-mdc-card-content {
    padding: 0 !important;
  }
}

.mat-mdc-card {
  padding: rz-space-multiplier(2);
  color: var(--theme-text-color);
  // &.just-added:not(:hover) {
  //   background-color: var(--theme-item-just-added);
  // }
}

.app-dark-theme .mat-mdc-card.child-mat-card-code {
  background: #1d1d1d;
  border: 2px double yellow;
}


