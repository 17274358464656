
@import 'libs/common/styles/functions/functions.scss';

.standard-width .mdc-dialog-container {
  min-height: 328px;
}

.large-width .mdc-dialog-container {
  width: 100vw;
}

.extra-large-width .mdc-dialog-container {
  width: 100vw;
}

.mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface {
  color: var(--theme-text-color)!important;
  padding: rz-space-multiplier(3);
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: var(--theme-text-color)!important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: var(--theme-text-color)!important;
}

.cdk-overlay-container {
  z-index: 88888 !important;
}

.mdc-dialog-actions {
  margin-top: 8px;
}

@media screen and (min-width: rz-breakpoint('small')) {
  .medium-width .mat-mdc-dialog-container {
    min-width: 500px;
    max-width: 750px;
  }
  
  .large-width .mdc-dialog__container {
    min-width: 750px;
  }

  .extra-large-width .mdc-dialog__container {
    max-width: 1024px;
  }
}
