.paths-sub-menu {
   .mat-mdc-menu-content, .mat-list {
      max-height: 400px !important;
   }
}

.mat-mdc-menu-item.mat-warn {
   color: var(--warn-color) !important;
}

.app-dark-theme {
   .mat-mdc-menu-item:hover {
      background-color: rgba($color: lightgrey, $alpha: 0.2) !important;
   }
}

.mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  gap: rz-space-multiplier(1);
}
 