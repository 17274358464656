@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import 'libs/common/styles/razroo-styles.scss';
@import 'libs/common/styles/functions/functions.scss';

::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
}

$tour-zindex: 99999;
$tour-next-button-color: var(--accent-color);
$tour-next-button-hover: var(--accent-color);
$tour-back-button-color: var(--primary-color);
$tour-text-color: var(--primary-color);

.root-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo-image {
    background-image: url('https://cdn.razroo.com/logos/razroo-logo.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    height: 60px;
    width: 180px;
  }
  .loader-animation {
    width: 300px;
    height: 4.8px;
    margin-top: 16px;
    display: inline-block;
    position: relative;
    background: rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }
  .loader-animation::after {
    content: '';
    width: 120px;
    height: 4.8px;
    background: var(--accent-color);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 1.5s linear infinite running;
  }

  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
}
.guided-tour-spotlight-overlay {
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.2) !important;
}
.full-width.mat-mdc-form-field-appearance .mat-mdc-form-field-wrapper {
  width: 100%;
}

.as-split-gutter {
  flex-basis: 8px !important;
  transition: 0.3s;
  background-color: #1d1d1d !important;
  &:hover {
    background-color: #1389fd !important;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  color: --theme-text-color;
  scroll-padding-top: 24px;
  &::-webkit-scrollbar {
    display: 'none';
    width: 0;
  }
  -ms-overflow-style: 'none' /*IE and Edge*/;
  scrollbar-width: 'none' /*Firefox*/;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.sendEmailDialogDropdown {
  max-height: 200px !important;
}

.mat-mdc-tab-tab-nav-bar .mat-ink-bar,
.mat-mdc-tab-tab-group .mat-ink-bar {
  background-color: var(--accent-color) !important;
}

.book-navigator {
  .mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label,
  .mat-mdc-form-field-can-float
    .mat-input-server:focus
    + .mat-mdc-form-field-label-wrapper
    .mat-mdc-form-field-label {
    display: none !important;
  }

  mat-form-field > * {
    margin-top: -4px;
  }
}

.mat-snack-bar-container {
  max-width: 600px !important;
}

.hljs-ln-n {
  margin-right: 15px;
}

.display-no-tab {
  .mat-mdc-tab-label-container {
    display: none;
  }
  .gtm-sandbox-class .mat-mdc-tab-label-container {
    display: block;
  }
}

// TODO - tidy up global. For now global is best option
.mat-typography .BookReader__content {
  $book-header-bottom-border: double 2px yellow;
  h1 {
    display: inline;
    font-size: 30px;
    line-height: 42px;
    border-bottom: $book-header-bottom-border;
  }
  h2 {
    display: inline;
    font-size: 26px;
    line-height: 38px;
    border-bottom: $book-header-bottom-border;
  }
  h3 {
    display: inline;
    font-size: 22px;
    line-height: 30px;
    border-bottom: $book-header-bottom-border;
  }
  h4 {
    display: inline;
    font-size: 20px;
    line-height: 28px;
    border-bottom: $book-header-bottom-border;
  }
  h5 {
    display: inline;
    font-size: 18px;
    line-height: 26px;
    border-bottom: $book-header-bottom-border;
  }
  p,
  li {
    font-size: 20px;
    line-height: 30px;
  }
}

.BookReader__form {
  mat-form-field {
    .mat-mdc-select-value {
      padding-left: rz-space-multiplier(1);
    }
    .mat-mdc-select-placeholder {
      color: white !important;
    }
  }
}

.teams-tooltip {
  font-size: 90% !important;
  width: fit-content !important;
  padding: 11px !important;
}

.app-light-theme {
  .as-split-gutter {
    background-color: #ababab !important;
    &:hover {
      background-color: #1389fd !important;
    }
  }

  .Stepper__step:hover {
    background-color: rgba($color: grey, $alpha: 0.1) !important;
  }
}

.app-dark-theme {
  .mat-option-text {
    color: var(--theme-text-color);
  }

  .EditTicketPage__description-view {
    // update coordinates so bottom left, true to a highlighter
    cursor: url('/assets/icons/highlighter-duotone-dark-mode.svg') 0 18, auto;
  }

  .mat-option.mat-selected {
    color: var(--theme-text-color);
    .mat-pseudo-checkbox {
      background-color: var(--theme-text-color);
    }
  }

  .mdc-tab-indicator__content--underline {
    border-color: var(--accent-color) !important;
  }
}

razroo-zeta-code-editor-tree-file {
  .mdc-checkbox {
    width: 14px !important;
    height: 14px !important;
    margin: 0px !important;
    margin-top: -4px !important;
    padding: 0% !important;
    flex: none !important;
  }
  .mdc-checkbox__background,
  .mdc-checkbox__native-control {
    height: 14px !important;
    width: 14px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .mat-mdc-checkbox-touch-target {
    height: 14px !important;
    width: 14px !important;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 0% !important;
    left: 0% !important;
  }
}

.mat-step-header[aria-selected='true'] {
  * {
    color: var(--accent-color) !important;
  }
}

.mat-horizontal-content-container {
  padding: 0% !important;
}

// Code Chat SCSS
$code-toolbar-font-size: 12.8px;
.CodeChat__chat-message {
  .markdown-clipboard-button {
    &:before {
      content: '📋';
      padding-right: rz-space-multiplier(0.5);
    }
    font-size: $code-toolbar-font-size;
    background-color: transparent;
    color: var(--theme-text-color);
    border: none;
    cursor: pointer;
  }
  div.code-toolbar > pre {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  div.code-toolbar > .toolbar {
    // background: var(--primary-lighter-color);
    background: var(--theme-code-tree-background-color);
    position: relative;
    opacity: 1;
    right: 0;
    top: rz-space-multiplier(1);
    > .toolbar-item > span {
      display: block;
      margin-left: rz-space-multiplier(1);
      background-color: transparent;
      color: var(--theme-text-color);
      box-shadow: none;
    }
  }

  .code-toolbar {
    display: flex;
    flex-direction: column-reverse;
    + div {
      opacity: 1 !important;
      z-index: 9999;
      top: 9px;
    }
  }
}

.history-search {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

// BEGIN SSR Fix - https://github.com/FortAwesome/angular-fontawesome/issues/48
.svg-inline--fa {
  vertical-align: -0.125em;
}

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
}

fa-icon .fa-2x {
  font-size: 2em;
}
// END SSR Fix

.uneditable-variable {
  color: var(--theme-uneditable-vars-color);
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--theme-disabled-text) !important;
}

.fa-icon {
  line-height: inherit !important;
}

.criteria-journey-text {
  ol {
    padding-left: rz-space-multiplier(2);
  }
}

.ticket-metadata-drawer {
  .mat-drawer-inner-container {
    overflow: hidden;
  }
}

.multiline-tooltip {
  white-space: pre-line;
  text-align: left;
}

.tab-label {
  .cdk-drop-list-dragging,
  .cdk-drag-placeholder {
    display: none;
  }
}

lib-description-prompt-box {
  .mat-mdc-tab-label-container {
    margin-right: 30px !important;
  }
}

.attachment-image-dialog {
  .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 0px !important;
  }
}

lib-edit-ticket-page {
  quill-view-html {
    .ql-editor {
      min-height: 150px;
    }
  }
}
.white-spinner.mat-mdc-progress-spinner.mdc-circular-progress {
  circle {
    stroke: whitesmoke !important;
  }
}

razroo-zeta-comment {
  .edit-view {
    .edit-form {
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        transform: translateY(-170%) scale(0.75) !important;
      }
    }
  }
}
