// had to put in important or else would not override design language
.app-dark-theme .mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: rgba(253, 228, 224, 0.7)!important;
}

.mat-mdc-option-active > .mdc-list-item__primary-text, .mat-mdc-select , .mat-mdc-select-arrow {
  color: var(--theme-text-color) !important;
}

// .mat-mdc-selection-list .mdc-list-item__end {
//   padding-left: 0;
// }

.mat-mdc-selection-list .mdc-list-item__end {
  margin-left: 0!important;
}

.mat-mdc-selection-list .mdc-list-item:not(.mdc-list-item--selected):focus::before {
  background-color: transparent!important;
}

.mat-mdc-selection-list .mdc-list-item__secondary-text {
  color: var(--theme-text-color)!important;
}

.mat-mdc-selection-list .mdc-list-item__secondary-text::before,
.mat-mdc-selection-list .mdc-list-item--with-two-lines .mdc-list-item__primary-text::before, 
.mat-mdc-selection-list .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: none!important;
}