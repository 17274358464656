@import './normalize';
@import './themes/themes';
@import './functions/_functions';
@import './material-overrides/material-overrides';
@import './quill-overrides/quill-overrides';
@import './tiptap-overrides/tiptap-overrides';
@import './editable-overrides/editable';
@import './virtual-scroll/virtual-scroll';

.wifejak-animation {
  animation: wifejak-animation 1.5s ease-in-out infinite;
}

.wifejak-walkthrough-highlight {
  border: 2px solid var(--theme-text-color);  
}

@keyframes wifejak-animation {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(19, 137, 253, 0.4);
  }
  50% {
    transform: scale(1.03);
    box-shadow: 0 0 0 8px rgba(19, 137, 253, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(19, 137, 253, 0);
  }
}
