@import './mat-icon';
@import './mat-dialog';
@import './mat-button';
@import './mat-sidenav';
@import './mat-stepper';
@import './mat-card';
@import './mat-expansion-panel';
@import './mat-select';
@import './mat-toolbar';
@import './mat-snackbar';
@import './mat-form';
@import './mat-chip';
@import './mat-viewport';
@import './mat-tooltips';
@import './mat-data-table';
@import './mat-radio-button';
@import './mat-list';
@import './mat-menu';
@import './mat-tab';
@import './mat-input';
@import './mat-divider';

razroo-zeta-relevant-questions {
  .mat-list-item.mat-list-item :hover {
    background-color: #f7f7f7;
    border-radius: 5px;
  }
}

.mat-step-header .mat-step-label.mat-step-label-active {
  line-height: initial;
}

mat-form-field {
  .mat-mdc-floating-label {
    top: 27px !important;
  }
}
