// Material Theme Generator
// https://materialtheme.arcsine.dev
@use 'sass:map';

@use '@angular/material' as mat;
@import './foreground-background-theme.scss';
@import './_fonts.scss';
@import './_light-code-theme.scss';
@import './_dark-code-theme.scss';
@import 'libs/common/styles/functions/functions.scss';
// Compute font config
@include mat.core();
@include mat.all-component-typographies($fontConfig);

// Theme Config
body {
  --primary-color: #072b21;
  --primary-lighter-color: #0c3a2f;
  --primary-darker-color: #0a3228;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$light-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #072b21,
  lighter: #0c3a2f,
  darker: #0a3228,
  200: #072b21,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $light-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #ff5733;
  --accent-darker-color: #f50057;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #ff5733,
  lighter: #ffb6a6,
  darker: #f50057,
  200: #ff5733,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ea414f;
  --warn-lighter-color: #f9c6ca;
  --warn-darker-color: #e12a36;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ea414f,
  lighter: #f9c6ca,
  darker: #e12a36,
  200: #ea414f,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$light-theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);
$dark-theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Theme Init
.app-light-theme {
  @include mat.all-component-themes($light-theme);
  
  * {
    --theme-card-background-color: #ffffff;
    --theme-toolbar-background-color: #ededed;
    --mdc-theme-primary: --theme-text-color;
    --theme-active-background-color: rgba(33, 33, 33, 0.4);
    --theme-text-color: #000000;
    --primary-lighter-color: white;
    --accent-lighter-color: #222222;
    --non-starter-file-name: #f50057;
    --theme-text-color-rgb: 0, 0, 0;
    --theme-white-text-color: white;
    --mdc-text-button-label-text-color: var(--theme-text-color)!important;
    --mdc-protected-button-label-text-color: var(--theme-text-color)!important;
    --mdc-filled-text-field-input-text-color: var(--theme-text-color)!important;
    --theme-code-tree-background-color: #ededed;
    --theme-disabled-text: rgba(0, 0, 0, 0.5);
    --theme-accent-text: #000000;
    --theme-razroo-logo: url('https://cdn.razroo.com/logos/razroo-logo.svg');
    --theme-razroo-logo-icon: url('https://cdn.razroo.com/logos/razroo_icon.svg');
    --active-file-color: #dddddd;
    --theme-terminal-text-color: black;
    --theme-file-hover-color: rgba(0,0,0,0.1) !important;
    --theme-item-just-added: rgba(0,0,0, 0.02);
    --theme-cms-toolbar-color: #ededed;
    --batch-color: blue;
    --theme-code-editor-background-color: #FFFFFE;
    --theme-editable-vars-color: blue;
    --theme-uneditable-vars-color: steelblue;
    --theme-text-inverted-color: #fde4e0;
    --theme-kanban-headers-background: #e6e6e6;
    --theme-custom-tabs-inkbar-color: #072b21;
    --theme-kanban-shimmer-gradient: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--theme-text-color);
  }
}

a {
  color: var(--accent-lighter-color);
  text-decoration: underline;
  &:hover {
    background: var(--theme-toolbar-background-color);
  }
}

.app-dark-theme {
  @include mat.all-component-themes($dark-theme);
  // TODO not sure why carot color not automatically affected
  .mat-input-element {
    caret-color: var(--theme-text-color);
  }

  * {
    --theme-card-background-color: #0a3228;
    --theme-toolbar-background-color: rgba(33, 33, 33, 1);
    --theme-active-background-color: rgba(33, 33, 33, 0.6);
    --theme-code-tree-background-color: #202327;
    --theme-text-color: #fde4e0;
    --mdc-text-button-label-text-color: var(--theme-text-color)!important;
    --mdc-protected-button-label-text-color: var(--theme-text-color)!important;
    --mdc-filled-text-field-input-text-color: var(--theme-text-color)!important;
    --theme-text-color-rgb: 253, 228, 224;
    --mdc-theme-primary: --theme-text-color;
    --accent-lighter-color: #ffb6a6;
    --non-starter-file-name: #ffb6a6;
    --primary-lighter-color: #0c3a2f;
    --mdc-chip-label-text-color: --theme-text-color;
    --theme-accent-text: #ffffff;
    --theme-disabled-text: rgba(255, 255, 255, 0.5);
    --theme-razroo-logo: url('https://cdn.razroo.com/logos/razroo-logo-light.svg');
    --theme-razroo-logo-icon: url('https://cdn.razroo.com/logos/razroo_icon.svg');
    --active-file-color: #37373c;
    --theme-terminal-text-color: white;
    --theme-file-hover-color: rgba(255,255,255, 0.1) !important;
    --theme-item-just-added: rgba(255,255,255, 0.02);
    --theme-cms-toolbar-color: #114f40;
    --theme-code-editor-background-color: #1E1E1E;
    --theme-editable-vars-color: yellow;
    --theme-uneditable-vars-color: yellowgreen;
    --batch-color: yellow;
    --theme-text-inverted-color: #000000;
    --theme-kanban-headers-background: #22423A;
    --theme-custom-tabs-inkbar-color: #ff5733;
    --theme-kanban-shimmer-gradient: linear-gradient(to right, #3A5754 8%,  #22423A 18%,#2a5148 33%);
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--theme-accent-text);
  }
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-icon {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

// .xterm-rows {
//   div {
//     overflow: scroll !important;
//     word-wrap: break-word !important;
//   }
// }

.mat-icon-button.approve-btn {
  height: rz-space-multiplier(7);
  width: rz-space-multiplier(7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-container {
  font-family: Roboto, Calibri, Tahoma, sans-serif;
}
