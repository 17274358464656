// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Thin.eot');
  src: local('Graphik Thin'), local('Graphik-Thin'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Thin.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Thin.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Thin.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-ThinItalic.eot');
  src: local('Graphik Thin Italic'), local('Graphik-ThinItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ThinItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ThinItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-SuperItalic.eot');
  src: local('Graphik Super Italic'), local('Graphik-SuperItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SuperItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SuperItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SuperItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SuperItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Medium.eot');
  src: local('Graphik Medium'), local('Graphik-Medium'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Medium.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Medium.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Black.eot');
  src: local('Graphik Black'), local('Graphik-Black'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Black.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Black.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Black.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Semibold.eot');
  src: local('Graphik Semibold'), local('Graphik-Semibold'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Semibold.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Semibold.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Super.eot');
  src: local('Graphik Super'), local('Graphik-Super'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Super.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Super.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Super.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Super.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-ExtralightItalic.eot');
  src: local('Graphik Extralight Italic'), local('Graphik-ExtralightItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ExtralightItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ExtralightItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-BlackItalic.eot');
  src: local('Graphik Black Italic'), local('Graphik-BlackItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BlackItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BlackItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-RegularItalic.eot');
  src: local('Graphik Regular Italic'), local('Graphik-RegularItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-RegularItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-RegularItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Light.eot');
  src: local('Graphik Light'), local('Graphik-Light'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Light.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Light.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Light.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Bold.eot');
  src: local('Graphik Bold'), local('Graphik-Bold'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Bold.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Bold.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Regular.eot');
  src: local('Graphik Regular'), local('Graphik-Regular'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Regular.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Regular.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-BoldItalic.eot');
  src: local('Graphik Bold Italic'), local('Graphik-BoldItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BoldItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BoldItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-SemiboldItalic.eot');
  src: local('Graphik Semibold Italic'), local('Graphik-SemiboldItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SemiboldItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SemiboldItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-LightItalic.eot');
  src: local('Graphik Light Italic'), local('Graphik-LightItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-LightItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-LightItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-Extralight.eot');
  src: local('Graphik Extralight'), local('Graphik-Extralight'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Extralight.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Extralight.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Extralight.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://cdn.razroo.com/fonts/graphik/Graphik-MediumItalic.eot');
  src: local('Graphik Medium Italic'), local('Graphik-MediumItalic'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-MediumItalic.woff2') format('woff2'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-MediumItalic.woff') format('woff'),
      url('https://cdn.razroo.com/fonts/graphik/Graphik-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
/// https://cdn.razroo.com/fonts/graphik/

@font-face {
  font-family: 'GT Sectra Display';
  src: url('https://cdn.razroo.com/fonts/GTSectraDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('https://cdn.razroo.com/fonts/GTSectraDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GTSectraDisplay-RegularItalic';
  src: url('https://cdn.razroo.com/fonts/GTSectraDisplay-RegularItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('https://cdn.razroo.com/fonts/GTSectraDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('https://cdn.razroo.com/fonts/GTSectraDisplay-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('https://cdn.razroo.com/fonts/GTSectraDisplay-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}


$fontConfig: (
  display-4:
    mat.m2-define-typography-level(
      112px,
      112px,
      300,
      'GT Sectra Display',
      -0.0134em
    ),
  display-3:
    mat.m2-define-typography-level(56px, 56px, 400, 'GT Sectra Display', -0.0089em),
  display-2:
    mat.m2-define-typography-level(45px, 48px, 400, 'GT Sectra Display', 0em),
  display-1:
    mat.m2-define-typography-level(34px, 40px, 400, 'GT Sectra Display', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 500, 'GT Sectra Display'),
  // Section heading corresponding to the <h1> tag.
  title: mat.m2-define-typography-level(20px, 32px, 500, 'GT Sectra Display'),
  // Section heading corresponding to the <h2> tag.
  subheading-2: mat.m2-define-typography-level(16px, 28px, 500, 'Roboto'),
  // Section heading corresponding to the <h3> tag.
  subheading-1: mat.m2-define-typography-level(16px, 25px, 500, 'Roboto'),
  // Section heading corresponding to the <h4> tag.
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto'),
  // Bolder body text
  body-1: mat.m2-define-typography-level(16px, 26px, 400, 'Graphik'),
  // Base body text
  button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto'),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto'),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px),
);

li > p {
  margin-bottom: 0!important;
}
