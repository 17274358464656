@import 'libs/common/styles/functions/functions.scss';

.mat-mdc-text-field-wrapper {
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border-color: var(--theme-text-color)!important;
  }
}

.mdc-floating-label {
  color: var(--theme-text-color)!important;
}

.app-dark-theme input::placeholder {
  color: var(--theme-disabled-text)!important;
}

// if a NON outline input outline remove background and padding left
.mat-mdc-form-field-type-mat-input .mat-mdc-text-field-wrapper {
  padding-left: 0!important;
  background: transparent!important;
}

.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  padding-left: rz-space-multiplier(1)!important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0!important;
}

// END
.app-dark-theme .mat-mdc-form-field-invalid .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {
  background-color: #ea414f!important;
}

.mat-mdc-form-field.full-width .mat-mdc-text-field-wrapper {
  width: 100%;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px!important;
  padding-bottom: 16px!important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px!important;
  padding-bottom: 8px!important;
}

.edit-in-place {
  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: -1.25em;
  }

  .mat-mdc-form-field-infix {
    padding-top: 0;
    border-top: 0;
  }
}

.code-form-field {
  .mat-mdc-form-field-flex {
    background-color: black;
  }
}

.app-dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: white!important;
}

.app-dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #ffffff6b!important;
}

// PAGE SPECIFIC SCSS
.GenerateTicket__clarification-question-input-container {
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 0!important;
  }
}

