.mdc-tab__text-label {
  color: var(--theme-text-color)!important;
}

.Tickets__sprint-tabs .mat-mdc-tab-labels .mat-mdc-tab.mat-mdc-tab-disabled {
  min-width: 0;
  padding: 0;
  opacity: 1;
  cursor: pointer!important;
  pointer-events: auto;
  * {
    pointer-events: auto;
  }
  
}