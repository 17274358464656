@import 'libs/common/styles/functions/functions.scss';
// by default all razroo chips will be contained on a single line

.mat-chip-listbox span, .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  flex-wrap: nowrap!important;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 0;
  }
}

// font weight is custom
.mat-mdc-chip {
  .mdc-evolution-chip__text-label {
    flex: 0 0 auto;
    align-items: center;
    font-weight: 500!important;
  }
}

//css block to make chips go to end
.mdc-evolution-chip-set.align-end {
  .mdc-evolution-chip-set__chips {
    display: flex;
  }
  .mat-mdc-chip {
    margin-left: auto;
  }
}

// Use for Code CMS Template Variable Chips
.mdc-evolution-chip-set.single-line .mdc-evolution-chip-set__chips {
  overflow-y: scroll;
  flex-flow: nowrap;
  white-space: nowrap;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-chip + .mat-mdc-chip {
  margin-left: rz-space-multiplier(0.5);
}

// TODO use css variables for styling
.app-dark-theme .mat-mdc-chip.mat-mdc-standard-chip.non-clickable {
  opacity: 0.85;
  background-color: rgba(29, 143, 115,0.4);
}

.comment-header{
  .mat-mdc-chip{
    min-height: 25px;
  }
}

.mat-mdc-chip-list-stacked {
  .mat-mdc-chip-list-wrapper {
    flex-wrap: wrap!important;
    flex-direction: row!important;
    align-items: initial!important;
  }
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing {
  color: var(--theme-text-color)!important;
}


