@import "../functions/functions";

.mdc-raised-button,
.mdc-flat-button,
.mdc-button {
  height: 40px!important;
  color: var(--theme-text-color)!important;
}

.mdc-icon-button.small-icon-button {
  width: rz-space-multiplier(3)!important;
  height: rz-space-multiplier(3)!important;
  padding: 0!important;
  border-radius: 4px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  .mdc-icon-button__ripple {
    border-radius: 4px!important;
  }
  i {
    font-size: 14px!important;
  }
}

.mdc-raised-button.thin-button,
.mdc-flat-button.thin-button,
.mdc-button.thin-button {
  height: 28px!important;
}

.mdc-evolution-chip__text-label, .mat-mdc-menu-item-text {
  display: flex;
}

.app-light-theme {
  .mat-mdc-unelevated-button.mat-warn, .mat-mdc-unelevated-button.mat-accent, .mat-mdc-raised-button.mat-accent, .mat-mdc-raised-button.mat-warn, .mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
    color: var(--theme-white-text-color)!important;
  }
}

.mat-mdc-outlined-button.mat-accent {
  color: var(--accent-color) !important;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  width: 100%;
}

.mdc-raised-button + .mdc-raised-button,
.mdc-raised-button + .mdc-flat-button,
.mdc-flat-button + .mdc-flat-button,
.mdc-flat-button + .mdc-button,
.mdc-flat-button + .mdc-stroked-button,
.mdc-button + .mdc-button{
  margin-left: rz-space-multiplier(1);
}

.mdc-slide-toggle + .mdc-button,
.mdc-slide-toggle + .mdc-flat-button {
  margin-left: rz-space-multiplier(2);
}

a + .mdc-raised-button,
a + .mdc-flat-button,
a + .mdc-button {
  margin-left: rz-space-multiplier(3);
}

.mdc-button.full-width {
  padding-left: 0;
  padding-right: 0;
  .mdc-button-wrapper {
    width: 100%;
  }
}

.thin-icon-button {
  $icon-size: 20px; // SASS variable

  --mdc-icon-button-icon-size: #{$icon-size};
  --mdc-icon-button-state-layer-size: #{$icon-size + 12};
  padding: 0;

  .mat-icon {
    height: $icon-size;
    width: $icon-size;
    font-size: $icon-size;
  }

  // https://github.com/angular/components/issues/27118
  .mat-mdc-button-touch-target {
    height: #{$icon-size + 12}!important;
    width: #{$icon-size + 12}!important;
  }
  .mat-mdc-button-persistent-ripple {
    border-radius: 0!important;
  }

}
